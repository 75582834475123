import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`::: {.page title=“Page 1”}
::: {.section}
::: {.section}
::: {.layoutArea}
::: {.column}
`}<strong parentName="p">{`CROSSFIT OPEN WORKOUT 20.1`}</strong>{`
:::
:::
:::`}</p>
    <p>{`::: {.section}
::: {.layoutArea}
::: {.column}
10 rounds for time of:`}</p>
    <p>{`8 ground-to-overheads `}<em parentName="p">{`(clean & jerk or snatch)`}</em></p>
    <p>{`10 bar-facing burpees`}</p>
    <p><img parentName="p" {...{
        "src": "https://s0.wp.com/wp-content/mu-plugins/wpcom-smileys/twemoji/2/72x72/2640.png",
        "alt": "♀"
      }}></img>{`{.wp-smiley}
65 lb.
`}<img parentName="p" {...{
        "src": "https://s0.wp.com/wp-content/mu-plugins/wpcom-smileys/twemoji/2/72x72/2642.png",
        "alt": "♂"
      }}></img>{`{.wp-smiley}
95 lb.`}</p>
    <p>{`Time cap: 15 minutes
:::
:::
:::
:::`}</p>
    <p>{`::: {.layoutArea}
::: {.column}
`}<em parentName="p">{`WORKOUT VARIATIONS`}</em></p>
    <p>{`Rx’d (Rx’d Ages 16-54)
`}<img parentName="p" {...{
        "src": "https://s0.wp.com/wp-content/mu-plugins/wpcom-smileys/twemoji/2/72x72/2640.png",
        "alt": "♀"
      }}></img>{`{.wp-smiley}
65 lb.\\
`}<img parentName="p" {...{
        "src": "https://s0.wp.com/wp-content/mu-plugins/wpcom-smileys/twemoji/2/72x72/2642.png",
        "alt": "♂"
      }}></img>{`{.wp-smiley}
95 lb.`}</p>
    <p>{`Scaled (Scaled Ages 16-54)\\
`}<img parentName="p" {...{
        "src": "https://s0.wp.com/wp-content/mu-plugins/wpcom-smileys/twemoji/2/72x72/2640.png",
        "alt": "♀"
      }}></img>{`{.wp-smiley}
45 lb., may step over bar on burpees
`}<img parentName="p" {...{
        "src": "https://s0.wp.com/wp-content/mu-plugins/wpcom-smileys/twemoji/2/72x72/2642.png",
        "alt": "♂"
      }}></img>{`{.wp-smiley}
65 lb., may step over bar on burpees`}</p>
    <p>{`Teenagers 14-15`}</p>
    <p><img parentName="p" {...{
        "src": "https://s0.wp.com/wp-content/mu-plugins/wpcom-smileys/twemoji/2/72x72/2640.png",
        "alt": "♀"
      }}></img>{`{.wp-smiley}
45 lb.
`}<img parentName="p" {...{
        "src": "https://s0.wp.com/wp-content/mu-plugins/wpcom-smileys/twemoji/2/72x72/2642.png",
        "alt": "♂"
      }}></img>{`{.wp-smiley}
65 lb.`}</p>
    <p>{`Scaled Teenagers 14-15`}</p>
    <p><img parentName="p" {...{
        "src": "https://s0.wp.com/wp-content/mu-plugins/wpcom-smileys/twemoji/2/72x72/2640.png",
        "alt": "♀"
      }}></img>{`{.wp-smiley}
35 lb., may step over bar on burpees
`}<img parentName="p" {...{
        "src": "https://s0.wp.com/wp-content/mu-plugins/wpcom-smileys/twemoji/2/72x72/2642.png",
        "alt": "♂"
      }}></img>{`{.wp-smiley}
45 lb., may step over bar on burpees`}</p>
    <p>{`Masters 55+`}</p>
    <p><img parentName="p" {...{
        "src": "https://s0.wp.com/wp-content/mu-plugins/wpcom-smileys/twemoji/2/72x72/2640.png",
        "alt": "♀"
      }}></img>{`{.wp-smiley}
45 lb.
`}<img parentName="p" {...{
        "src": "https://s0.wp.com/wp-content/mu-plugins/wpcom-smileys/twemoji/2/72x72/2642.png",
        "alt": "♂"
      }}></img>{`{.wp-smiley}
65 lb.`}</p>
    <p>{`Scaled Masters 55+`}</p>
    <p><img parentName="p" {...{
        "src": "https://s0.wp.com/wp-content/mu-plugins/wpcom-smileys/twemoji/2/72x72/2640.png",
        "alt": "♀"
      }}></img>{`{.wp-smiley}
35 lb., may step over bar on burpees
`}<img parentName="p" {...{
        "src": "https://s0.wp.com/wp-content/mu-plugins/wpcom-smileys/twemoji/2/72x72/2642.png",
        "alt": "♂"
      }}></img>{`{.wp-smiley}
45 lb., may step over bar on burpees`}</p>
    <p><strong parentName="p">{`*`}{`We will be pairing up today and judging each other on this wod
during the 6am, 10:30am, and 12:00pm classes.  Those signed up for the
Open will need an official judge and scoresheet.  During this evenings
normal class times (4:00pm-7:00pm) we will have our Friday Night
Throwdown and run heats until everyone is done!  Good luck to all!`}</strong>{`
:::
:::
:::`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      